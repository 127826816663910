import React, { useState } from 'react';
import Modal from './Modal';
import './ConfirmDeleteModal.css';

const ConfirmDeleteModal = ({ onClose, onConfirm, userType, customer_name }) => {
  const [reason, setReason] = useState('');

  const handleConfirm = () => {
    onConfirm(reason);
  };

  const handleSelectChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <Modal onClose={onClose}>
      <div className="confirm-delete-modal">
      <p className="h1style">Confirm Delete for ({customer_name})</p>
        <div className="form-group">
          {userType === 'user' ? (
            <select value={reason} onChange={handleSelectChange} className="reason-select">
              <option value="" disabled>Select a reason</option>
              <option value="wait-time">Wait time is too long</option>
              <option value="found-other">Found another restaurant</option>
              <option value="no-waiting-area">No waiting area</option>
              <option value="other">Other</option>
            </select>
          ) : (
            <select value={reason} onChange={handleSelectChange} className="reason-select">
              <option value="" disabled>Select a reason</option>
              <option value="customer-did-not-turn-up">Customer did not turn up</option>
              <option value="customer-did-not-answer-the-call">Customer did not answer the call</option>
              <option value="found-another">Customer found another restaurant</option>
              <option value="too-many-people">Too many people in the queue</option>
              <option value="other">Other</option>
            </select>
          )}
        </div>
        <div className="actions">
          <button onClick={onClose} className="cancel-button">Cancel</button>
          <button onClick={handleConfirm} className="confirm-button">Confirm</button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;