import React, { useState, useEffect } from 'react';
import './CustomerHistoryModal.css';
import QueueService from './QueueService';

const CustomerHistoryModal = ({ customerId, token, onClose }) => {
  const [customerDetails, setCustomerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedVisit, setSelectedVisit] = useState(null);

  const getDaysAgo = (dateString) => {
    const visitDate = new Date(dateString);
     // IST offset in milliseconds
    const today = new Date(new Date().getTime()); // Adjusting today's date to IST
    const diffTime = Math.abs(today - visitDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getMealType = (dateString) => {
    const visitDate = new Date(dateString);
    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const hours = new Date(visitDate.getTime() + istOffset).getHours(); // Adjusting visit date to IST
    if (hours >= 6 && hours < 18.5) {
      return 'Lunch';
    } else {
      return 'Dinner';
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  const handleDateClick = (visitId) => {
    setSelectedVisit(selectedVisit === visitId ? null : visitId);
  };

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const details = await QueueService.getCustomerHistory(customerId, token);
        setCustomerDetails(details);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerDetails();
  }, [customerId, token]);

  if (loading)
    return (
      <div className="modal-overlay" onClick={handleClickOutside}>
        <div className="modal">
          <div className="modal-content-history">
            <div>Loading...</div>
          </div>
        </div>
      </div>
    );

  if (!customerDetails)
    return (
      <div className="modal-overlay" onClick={handleClickOutside}>
        <div className="modal">
          <div className="modal-content-history">
            <div>No customer details found</div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="modal-overlay" onClick={handleClickOutside}>
      <div className="modal">
        <div className="modal-header">
          <p>Customer History ({customerDetails?.length || 0} Visits)</p>
          <button className="close-button" onClick={onClose}>
        
          </button>
        </div>
        <div className="modal-content-history">
          <div className="visit-history">
            {customerDetails?.map((visit) => (
              <div key={visit.id} className="visit-row">
                <div 
                  className="days-ago" 
                  onClick={() => handleDateClick(visit.id)}
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <span>
                    {getDaysAgo(visit.created_at) === 0 ? 'Today' : getDaysAgo(visit.created_at) === 1 ? 'Yesterday' : `${getDaysAgo(visit.created_at)} days ago`} | {getMealType(visit.created_at)}
                  </span>
                  {selectedVisit === visit.id && (
                    <span className="exact-date-time">
                      {new Date(new Date(visit.created_at).getTime() + (5.5 * 60 * 60 * 1000)).toLocaleString('en-IN', { 
                        day: 'numeric', 
                        month: 'short', 
                        year: '2-digit', 
                        hour: 'numeric', 
                        minute: 'numeric', 
                        hour12: true 
                      })}
                    </span>
                  )}
                </div>
                <hr className="gray-line" />
                <div class="history-card-details">
                  <div class="2nd-line">
                    <div class="history-card-rating no-review">---</div>
                    <span className="visit-name">
                      <i className="fas fa-map-marker-alt" style={{ fontSize: '12px', color: '#4B4B4B' }}></i> {visit.name.length > 20 ? `${visit.name.substring(0, 17)}...` : visit.name}
                    </span>
                  </div>
                  <div className={`status-indicator ${visit.status}`}>
                    {visit.status}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <button className="secondary-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerHistoryModal;