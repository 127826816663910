import React, { useState } from 'react';
import Modal from './Modal';
import './ConfirmEntryModal.css';

const AddRemarkModal = ({ onClose, onConfirm }) => {
  const [remark, setRemark] = useState('');

  const handleConfirm = () => {
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds (epoch)
    onConfirm(remark, currentTime);
  };

  return (
    <Modal onClose={onClose}>
      <div className="confirm-entry-modal">
      <p className="h1style">Add Remark</p>
        {/* <p>Add Remark for the user</p> */}
        <div className="form-group">
          {/* <label>Table No (optional):</label> */}
          <input
            type="text"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="actions">
          <button onClick={onClose} className="cancel-button">Cancel</button>
          <button onClick={handleConfirm} className="confirm-button">Confirm</button>
        </div>
      </div>
    </Modal>
  );
};

export default AddRemarkModal;