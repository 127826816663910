import React from 'react';
import Modal from './Modal';
import './ConfirmEntryModal.css';

const CallModal = ({ onClose, onConfirm, customer_name }) => {
  return (
    <Modal onClose={onClose}>
      <div className="confirm-entry-modal">
      <p className="h1style">Confirm System Call to ({customer_name})</p>
        <p className="h2style">Are you sure?</p>
        <div className="actions">
          <button onClick={onClose} className="cancel-button">Cancel</button>
          <button onClick={onConfirm} className="confirm-button">Yes, Call</button>
        </div>
      </div>
    </Modal>
  );
};

export default CallModal;