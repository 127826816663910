import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faTable, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import './QueueManager.css';
import BusinessSettings from './BusinessSettings';
import AllEnteredPage from './AllEnteredPage';
import QrCodePage from './QrCodePage';
import TableSettingsPage from './TableSettingsPage';
import QueueTable from './QueueTable';
import MenuPage from './MenuPage';

const QueueManager = ({ business_id, business_user_id, token, handleLogout }) => {
  const [currentPage, setCurrentPage] = useState(() => localStorage.getItem('currentPage') || 'home');

  useEffect(() => {
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  const renderPage = () => {
    switch (currentPage) {
      case 'home':
        return <QueueTable business_id={business_id} business_user_id={business_user_id} token={token} handleLogout={handleLogout} />;
      case 'customers':
        return <AllEnteredPage token={token} business_id={business_id} />;
      case 'qr-code':
        return <QrCodePage token={token} business_id={business_id} />;
      case 'table-settings':
        return <TableSettingsPage token={token} business_id={business_id} />;
      case 'business-settings':
        return <BusinessSettings token={token} business_id={business_id} />;
      case 'menu':
        return <MenuPage handleLogout={handleLogout}/>;
      default:
        return null;
    }
  };

  return (
    <div className="main-container">
      <div className="page-content">
        {renderPage()} {/* Render the current page */}
      </div>

      {/* Bottom navigation bar */}
      <div className="bottom-nav">
        <button className={currentPage === 'home' ? 'nav-button selected' : 'nav-button'} onClick={() => setCurrentPage('home')}>
          <FontAwesomeIcon icon={faHome} size="lg" />
          <span>Home</span>
        </button>
        <button className={currentPage === 'table-settings' ? 'nav-button selected' : 'nav-button'} onClick={() => setCurrentPage('table-settings')}>
          <FontAwesomeIcon icon={faTable} size="lg" />
          <span>Table</span>
        </button>
        <button className={currentPage === 'customers' ? 'nav-button selected' : 'nav-button'} onClick={() => setCurrentPage('customers')}>
          <FontAwesomeIcon icon={faUsers} size="lg" />
          <span>Customers</span>
        </button>
        <button className={currentPage === 'menu' ? 'nav-button selected' : 'nav-button'} onClick={() => setCurrentPage('menu')}>
          <FontAwesomeIcon icon={faEllipsisH} size="lg" />
          <span>More</span>
        </button>
      </div>
    </div>
  );
};

export default QueueManager;
