import React, { useState } from 'react';
import Modal from './Modal';
import './ConfirmEntryModal.css';

const WaitingTimeEdit = ({ onClose, onConfirm, user }) => {
  const [newWaitTime, setNewWaitTime] = useState(user.initial_waiting_time || ''); // Initialize with user's current wait time
  const [loading, setLoading] = useState(false); // Add loading state

  const handleConfirm = async () => {
    setLoading(true); // Set loading to true when API call starts
    try {
      // Call onConfirm with the user and the new waiting time
      await onConfirm(user, newWaitTime);
    } finally {
      setLoading(false); // Set loading to false when API call ends
    }
  };

  return (
    <Modal onClose={onClose}>
      <div className="confirm-entry-modal">
      <p className="h1style">Edit Wait Time for {user.customer_name}</p>
        <div className="form-group">
          <label>New Waiting Time (minutes):</label>
          <input
            type="number"
            value={newWaitTime}
            onChange={(e) => setNewWaitTime(e.target.value)}
            className="form-control"
            min="0" // Ensure no negative values
            step="1" // Ensure step increments are whole numbers
          />
        </div>
        <div className="actions">
          <button onClick={onClose} className="cancel-button">Cancel</button>
          {/* Disable button when loading is true and change text to 'Editing...' */}
          <button onClick={handleConfirm} className="confirm-button" disabled={loading}>
            {loading ? 'Editing...' : 'Confirm'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WaitingTimeEdit;
